import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { ArrowLeftIcon } from "@primer/octicons-react"

import SubFooter from "components/sub-footer"
import SEO from "components/seo"

const BlogPostTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        keywords={frontmatter.tags}
        image={frontmatter.image.publicURL}
        ogtype="article"
        meta={[
          {
            name: `author`,
            content: frontmatter.author,
          },
          {
            property: `article:author`,
            content: frontmatter.link,
          },
          {
            property: `article:published`,
            content: frontmatter.date,
          },
        ].concat(
          frontmatter.tags.map((tag) => {
            return {
              name: `article:tag`,
              content: tag,
            }
          })
        )}
      />

      <div className="flex items-center justify-center mx-auto overflow-x-hidden max-w-screen-2xl h-fit">
        <div className="flex items-center justify-center w-full pt-20 md:pt-32">
          {false && (
            <div className="flex items-center justify-center h-16 space-x-4 col-span-2 bg-blue-light">
              <p className="text-lg font-black text-white">
                Clean up your code base with automatic refactorings.
              </p>
              <span className="flex items-center space-x-2.5 text-base font-black text-orange-light">
                Learn more <ArrowLeftIcon />
              </span>
            </div>
          )}

          {false && (
            <div className={` col-span-1 `}>
              <img
                className="h-full mx-auto"
                src={frontmatter.image.publicURL}
                alt=""
              />
            </div>
          )}
          <div className="flex flex-col justify-around h-full px-4 text-center lg:max-w-3xl lg:px-16">
            <div className="mt-7">
              <h3 className="text-2xl font-black lg:text-5xl text-magento-dark leading-10">
                {frontmatter.title}
              </h3>
              <p className="mt-6 text-xl font-medium opacity-50 text-black-dark text-magento-dark lg:mr-5 lg:mr-0">
                {frontmatter.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="visible md:invisible flex flex-row justify-center mt-5 space-x-5">
        <div className="flex flex-row space-x-3">
          <span className="text-xs text-black opacity-50">Author</span>
          <p className="text-base font-bold text-black hover:text-blue-link">
            <OutboundLink href={frontmatter.link}>
              {frontmatter.author}
            </OutboundLink>
          </p>
        </div>
        <div className="flex flex-row space-x-3">
          <span className="text-xs text-black opacity-50">Date</span>
          <p className="text-base font-bold text-black">
            {frontmatter.prettydate}
          </p>
        </div>
      </div>
      <div className="items-center justify-center max-w-5xl mx-5 lg:mx-auto mb-5">
        <div className="md:grid md:grid-cols-5">
          <div className="flex flex-wrap items-center hidden col-span-1 gap-x-4 md:gap-x-0 md:space-y-7 md:mt-8 md:items-start md:flex-col md:block">
            <div className="flex flex-col space-y-1">
              <span className="text-xs text-black opacity-50">Author</span>
              <p className="text-base font-bold text-black hover:text-blue-link">
                <OutboundLink href={frontmatter.link}>
                  {frontmatter.author}
                </OutboundLink>
              </p>
            </div>
            {false && (
              <div className="flex flex-col space-y-1">
                <span className="text-xs text-black opacity-50">Category</span>
                <p className="text-base font-bold text-black">Code</p>
              </div>
            )}
            <div className="flex flex-col space-y-1">
              <span className="text-xs text-black opacity-50">Date</span>
              <p className="text-base font-bold text-black">
                {frontmatter.prettydate}
              </p>
            </div>
            <div>
              <Link
                to="/blog/"
                className="flex items-center font-bold text-center space-x-4 text-purple-medium lg:text-left"
              >
                <span>Back to Blog Home</span>
              </Link>
            </div>
          </div>

          <div
            className="col-span-3 prose limit-width"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>

      <SubFooter />
    </>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          publicURL
        }
        prettydate: date(formatString: "MMM DD, YYYY")
        tags
        author
        link
      }
    }
  }
`
